import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as JsEncryptModule from 'jsencrypt';
import CryptoJS from 'crypto-js';
declare var require: any;
var randomize = require('randomatic');

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {


  httpHeaders = new HttpHeaders();
  publicKey: string;
  randomKey: string;
  jsEncrypt: any;
  salt: any;
  iv: any;
  passPhrase: any;
  cipherObj: any;
  request: any;

  constructor(private httpClient: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    // private apiService: ApiService
    ) {

    // this.httpHeaders = this.httpHeaders.set('Content-Type', 'application/json');
    // this.iv = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    // this.salt = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    // this.passPhrase = randomize('0', 16);
     }

   
  //The set method is use for encrypt the value.
  keySize = 128 / 32;
  iterationCount = 1000;

  generateKey(salt, passPhrase) {

    var key = CryptoJS.PBKDF2(
        passPhrase,
        CryptoJS.enc.Hex.parse(salt),
        { keySize: this.keySize, iterations: this.iterationCount });
    return key;
  }


  encrypt(key,iv, plainText) {

    var encrypted = CryptoJS.AES.encrypt(
        plainText,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  decrypt(key,iv, cipherText1) {

    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText1)
    });
    var decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // submit() {
  //   this.request =  {
  //     key : "key test",
  //     value: "value test"
  //   }
  //   var genKey = this.generateKey(this.salt, this.passPhrase);

  //   localStorage.setItem('solo', JSON.stringify(genKey));
  //   var g = JSON.parse(localStorage.getItem('solo'));

  //   var ciphertext = this.encrypt(g, JSON.stringify(this.request));

  //   this.cipherObj = {
  //     payLoad: ciphertext
  //   }
  //   this.apiService.post_service(ApiService.apiList.testUrl, this.cipherObj)
  //       .subscribe(
  //         response => {
  //           console.log(response);
  //           var tet = this.decrypt(g, response.payLoad);
  //           console.log(JSON.parse(tet));
  //         },
  //         error => {
  //           console.log(':::: ERROR ::::');
  //         }
  //       );
  // }

}
