import { AuthenticationService } from './../services/authentication.service';
import { ApiService } from './../services/api.service';
import { HelperService } from './../services/helper.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HeaderComponent],
  providers:[HelperService,ApiService,AuthenticationService],
  exports: [
    HeaderComponent,
],
})
export class HeaderModule { }
