import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "MSIG";
  isLoginPage: boolean = false;
  isLoading: boolean = false;
  constructor(
    public authService: AuthenticationService,
    public router: Router
  ) {
    router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        setTimeout(() => {
          this.isLoading = true;
        }, 0);
      }
      else if(event instanceof NavigationEnd) {
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }
      else if(event instanceof NavigationEnd) {
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }
    }
    )
  }
}
