import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private router : Router) {};
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    
   // return true;
   var userRoles:string[] = route.data.roles;
  //  console.log("roles : ",userRoles);
  //  console.log("length : ",userRoles.length);
   
   var loggedInData = JSON.parse(localStorage.getItem("userResponse"));
   if(userRoles.length > 0){
     if(loggedInData){
     var role = userRoles.filter(element => element === loggedInData.role);
     if(role.length > 0){
       return true;
     }else{
      this.router.navigateByUrl('/msig/dashboard');
      return false;
     }
     }
   }
   let authToken = localStorage.getItem("authToken");
    if(authToken &&  authToken != null){
      return true;
     }else{
       this.router.navigateByUrl('');
      return false;
     }

  }

  canActivateChild() {
    let authToken = localStorage.getItem("authToken");
    if(authToken &&  authToken != null){
      return true;
     }else{
       this.router.navigateByUrl('');
      return false;
     }

  }

}
