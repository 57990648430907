import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userResponse: any;

  constructor() { }

  ngOnInit() {
 
    this.userResponse = JSON.parse(localStorage.getItem("userResponse"))
  }

}
