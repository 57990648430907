import { environment } from './../../environments/environment.prod';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
declare var moment: any;

@Injectable({
  providedIn: "root"
})
export class HelperService {
  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
  parseDate(val: number) {
    if (val < 10) {
      return String('0' + val);
    } else {
      return val;
    }
  }
  getDateAsStringWithoutTrim(time: number) {
    const date = moment(time).format('L');
    return date.split('/')[1] + '/' + date.split('/')[0] + '/' + date.split('/')[2];
  }
  getDateAsObject(time:number) {
    const date = moment(time).format('L');
    return {
      'day': date.split('/')[1].replace(/^0+/, ''), 'month': date.split('/')[0].replace(/^0+/, ''), 'year': date.split('/')[2]
    };
  }
  getDateAndTimeAsStringWithoutTrim(time: number) {
    const date = moment(time);
    return date;
  }
}
