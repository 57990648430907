import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isLoginPage:boolean = false;
  constructor(private httpClient: HttpClient, private router: Router,     public toastr: ToastrService,
  ) { }

  
  isLoggedIn() {
    let Url = window.location.href;
  this.isLoginPage = (Url.split('/')[3] == 'login' || Url.split('/')[3] == '') ? true : false;
    return this.isLoginPage;
    
  }

  
}

