import { LoaderModule } from './loader/loader.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { HeaderComponent } from 'src/app/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AuthenticationService } from './services/authentication.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CancerCareCompletePaymentComponent } from './public/cancer-care-complete-payment/cancer-care-complete-payment.component';

@NgModule({
  declarations: [
    AppComponent,
    CancerCareCompletePaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HeaderModule,
    LoaderModule,
    RouterModule,
   
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton:false
    }),
    UiSwitchModule
    // .forRoot({
    //   size: 'small',
    //   color: 'rgb(27, 24, 98);',
    //   switchColor: '#1b1862',
    //   defaultBgColor: '#00ACFF',
    //   defaultBoColor : '#476EFF',
    //   checkedLabel: 'on',
    //   uncheckedLabel: 'off'
    // })
  ],
  providers: [AuthenticationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
