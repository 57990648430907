import { EncryptionService } from 'src/app/services/encryption.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs';
declare var $: any;
declare var require: any;

@Injectable({
  providedIn: "root"
})
export class ApiService {
  srcFile:any;
  cipherObj: any;
  requestData: any;
  httpHeaders = new HttpHeaders();

  request: any;
  publicKeyCount: number = 1;
  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private encryption: EncryptionService
  ) {
    this.httpHeaders = this.httpHeaders.set("Content-Type", "application/json");

  }

  logoutCount: number = 0;

  public static apiList = {
    //admin login
    admiLoginApi: "user/login",
    //add FieldAgent
    addFieldAgent: "api/admin/v1/field-agent",
    //getAllFieldAgent List
    getAllFieldAgents: 'api/admin/v1/field-agent-list',
    //gatFieldAgentById
    getAllFieldAgentById: 'api/admin/v1/field-agent',
    //updateFieldAgent
    updateFieldAgent: 'api/admin/v1/field-agent',
    //deactivateField Agent
    deactivateFieldAgent: 'api/admin/v1/field-agent',
    //getAllAdmins
    getAllAdmins: 'api/admin/v1/sub-admin-list',
    //getAdminDetailsById
    getAdminById: 'api/admin/v1/sub-admin',
    //addAdmin
    addAdmin: 'api/admin/v1/sub-admin',
    //updateAdmin
    updateAdmin: 'api/admin/v1/sub-admin',
    //deactivate Admin
    deactivateAdmin: 'api/admin/v1/sub-admin',
    //getAllPreexQuestions
    getAllPremedicalQuestions: 'api/sample/v1/pre-medical-question',
    //addPremedicalQuestion
    addPreMedicalQuestions: 'api/sample/v1/pre-medical-question',
    //deleteMedicalQuestion
    deleteMedicalQuestion: 'api/sample/v1/pre-medical-question',
    //getAllTravellingToList
    getAllTravelAreas: 'api/sample/v1/travel-area',
    //createNewTravel
    addNewTravels: 'api/sample/v1/travel-area',
    //deleteTravelArea
    deleteTravels: 'api/sample/v1/travel-area',
    //addNewbranch
    addnewBranch: 'api/sample/v1/department',
    //delete branch
    deleteBranch: 'api/sample/v1/department',
    //getAllchannel
    getAllChannel: 'api/sample/v1/channel',
    //saveChannel
    addNewChannel: 'api/sample/v1/channel',
    //deleteChannel
    deleteChannel: 'api/sample/v1/channel',
    //getAllFirms
    getAllFirms: 'api/sample/v1/firm',
    //saveFirm
    addNewFirm: 'api/sample/v1/firm',
    //deleteFirm
    deleteFirm: 'api/sample/v1/firm/',
    //getAllCountriesList
    getAllCountries: 'api/common/v1/get-countries',
    //activateCountries
    activateCountries: 'api/sample/v1/nationality',
    //branch List
    getBranchList: 'api/common/v1/get-branch-list',

    qas: '/api/sample/v1/qas',  
    getQas: '/api/sample/v1/qas',

    //Six moths
    getLastSixMonth: 'api/common/v1/get-last-six-months',
    //channelList
    getAllChannelList: 'api/sample/v1/channel',

    //comman logout
    logout: "saml/logout?local=true",
    //logout: "/api/user/logout",

    getPaymentInfo: "payment/get-info",
    loadPaymentPage: "api/payment-page",
    getTransactionInfo: "payment/get-request-data",
    updateCancerCarePayment: "/payment/get-policy",
    initiateAuthentication: "/payment/initiate-authentication",

    //alphabetical order channels
    getBranchFirmChannelList: 'api/common/v1/get-branch-firm-channel-list',
    getInsuranceTypes: 'api/common/v1/insurance-types',

    //Stuck Quotation
    stuckQuote: '/api/reports/v1/stuck',
    stuckQuoteInfo: '/api/reports/v1/stuck',
    getFilterQuotesData: 'api/reports/v1/stuck',

    // Dashboard
    // getDropdownsData : 'api/common/v1/get-branch-firm-channel-intermediary-products-list',
    getDropdownsData: 'api/reports/v1/get-firm-branch-channel-intermediary-with-filter',
    getDashboardData: 'api/reports/v1/get-quotation-count-info?monthType=',
    productsData: 'api/reports/v1/get-product-wise-graph',
    intermedairyData: 'api/reports/v1/get-intermediary-sales-transaction',

    // reports
    getUserReportsData: 'api/reports/v1/get-user-report',
    getSalesReportsData: 'api/reports/v1/get-sales-report',
    getSalesConversionReportsData: 'api/reports/v1/get-sales-conversion-report',
    getQuoatationReportsData: 'api/reports/v1/get-quotation-report',
    getEndorsementReportsData: 'api/reports/v1/get-endorsement-report',
    getDailyStatisticsReportsData: 'api/reports/v1/get-intermediary-product-wise-sales-graph',
    getQuoteActiveUsersData: 'api/reports/v1/get-quote-active-users-graph',
    getAuditTrailData: 'api/reports/v1/get-audit-trial-report',
    getAuditTrailDropDownData : 'api/reports/v1/get-audit-trial-dropdown-data',
    updateTransaction: 'api/reports/v1/update-transaction',

    //Excel download reports
    getSalesReportExcel: 'api/reports/v1/download-sales-report-excel',
    getSalesConversionReportExcel: 'api/reports/v1/download-sales-conversion-report-excel',
    getQuotationReportExcel: 'api/reports/v1/download-quotation-report-excel',
    getEndorsementReportExcel: 'api/reports/v1/download-endorsement-report-excel',
    getUserReportExcel: 'api/reports/v1/download-user-report-excel',
    getAuditTrailReportExcel: 'api/reports/v1/download-audit-trail-report-excel',

    //Bulk Upload
    downloadBulkUpload: '/api/admin/v1/download-intermediary-sample',
    uploadExcel: 'api/admin/v1/upload-intermediary',

    //BroadCast List
    getBroadCastList: '/api/reports/v1/broadcast',
    createUpdateBroadCast: '/api/admin/v1/save-update-broadcast',
    getBroadCastById: '/api/reports/v1/broadcast',
    deleteBroadCastById: '/api/admin/v1/broadcast',

    //Encryption
    getPublickey : 'api/sso-wrapper/v1/get-public-key',
    testUrl:'api/sso-wrapper/v1/test-aes',
    saveAesInfo:'api/sso-wrapper/v1/save-aes-key',

    //Email template
    getAllTemplates: 'api/common/v1/get-email-templates',
    saveTemplates: 'api/admin/v1/save-email-templates',

    //intermediary-data-excel
    getInterMediaryExcelData:'api/admin/v1/download-intermediary-data',
    uploadInterMediaryCommissionContractExcelData:'api/admin/v1/update-intermediary-commission-contract',

    //admin-upload
    getManageAdminExcelSample:'api/admin/v1/download-manage-admin-sample',
    uploadManageaAminExcel:'api/admin/v1/upload-manage-admin-excel',

    //groupusers
    getAllFirmsList: 'api/common/v1/get-branch-firm-channel-list',
    getAllPartnerList:'api/admin/v1/get-partnership-list',
    getAllUserList: 'api/admin/v1/get-user-by-firm-id',
    addGroupUser:'api/admin/v1/save-user-agency-group'

  };
  //console.log("localvariable : "+localvariable);
  // let random = "90fe105afd66d58db588e4ea12094f2bda03'"       
  get_service(url) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }
    let random = localStorage.getItem('random');
    //  let random = "90fe105afd66d58db588e4ea12094f2bda03'"  
    // console.log(random, 'send radnom -1');

    // if (random) {
    //   random = random.replace(/['\0]/g, '');
    // }
    // console.log(random, 'remove signle quote and null character in random uid - random -2');
    

    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };

    //console.log(headerJson);
    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));

    return this.http.get(environment.backendUrl + url, httpOptions).pipe(
      map((response: any) => {
        if (response.status) {
          var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
        return JSON.parse(responseString);
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  get_service_by_id(url, id) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }
    //console.log("localvariable : "+localvariable);
    let random = localStorage.getItem('random');
  


    //console.log(headerJson);
    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));
    var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(id));

    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random,
      'ID': ciphertext
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    return this.http.get(environment.backendUrl + url, httpOptions).pipe(
      map((response: any) => {
        if (response.status) {
          var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
        return JSON.parse(responseString);
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  get_service_logout(url) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }
    //console.log("localvariable : "+localvariable);

    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };

    //console.log(headerJson);

    return this.http.get(environment.backendUrl + url, httpOptions).pipe(
      map((response: any) => {
        return JSON.parse(response);
      }),
      catchError(error => this.handleError(error))
    );
  }

  post_service(url, data) {

    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");

    }
    let random = localStorage.getItem('random');
    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));

    var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(data));

    this.cipherObj = {
      payLoad: ciphertext
    };

    return this.http.post(environment.backendUrl + url, this.cipherObj, httpOptions).pipe(
      map((response: any) => {
        if (response.status) {
          var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
        return JSON.parse(responseString);
        } else {
          return response;
        }
      }),
      catchError(error => this.handleError(error))
    );
  }
  multiPart_post_service(url, data) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");

    }

    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));
    var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(data));
    this.cipherObj = {
      payLoad: ciphertext
    };
    let random = localStorage.getItem('random');
    let headerJson = {
      // "Content-Type": "multipart/form-data",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    return this.http.post(environment.backendUrl + url, this.cipherObj, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  multiPart_excel_post_service(url, data) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");

    }

   
    let random = localStorage.getItem('random');
    let headerJson = {
      // "Content-Type": "multipart/form-data",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    return this.http.post(environment.backendUrl + url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getExcelFileData(url) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");

    }
    let headerJson = {
      "X-AUTH-TOKEN": localvariable
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    return this.http.get(environment.backendUrl + url,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  Put_service(url, id, data) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }

    let random = localStorage.getItem('random');
    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));

    var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(id));

    var cipherData = this.encryption.encrypt(genKey, iv, JSON.stringify(data));

    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random,
      'ID': ciphertext
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    this.cipherObj = {
      payLoad: cipherData
    };
    return this.http.put(environment.backendUrl + url, this.cipherObj, httpOptions).pipe(
      map((response: any) => {
        if (response.status) {
          if(response.payLoad){

            var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
            return JSON.parse(responseString);
          }else{
            return response;
          }
        }  else {
          return response;
        }
      }),
      catchError(error => this.handleError(error))
    );
  }
  delete_Service(url, id) {
    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }
    let random = localStorage.getItem('random');
    var genKey = JSON.parse(localStorage.getItem('identificationNo'));
    var iv = (localStorage.getItem('iv'));

    var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(id));
    let headerJson = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localvariable,
      'RANDOM-UID': random,
      'ID': ciphertext
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };
    return this.http.delete(environment.backendUrl + url, httpOptions).pipe(
      map((response: any) => {
        // if (response.status) {
        // var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
          // return JSON.parse(responseString);
        // } else {
          return response;
        // }
      }),
      catchError(error => this.handleError(error))
    );
  }

  // logout(){

  //   this.get_service(ApiService.apiList.logout)
  //   // .subscribe(
  //   //   response => {       
  //   // localStorage.clear();
  //   // // this.router.navigateByUrl('/');
  //   //   }
  //   //   // },
  //   //   // error => {
  //   //   //   this.toastr.error("Something went wrong");
  //   //   // }
  //   // );

  //   .pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError(error => this.handleError(error))
  //   );
  // }

  private handleError(errorObj: HttpErrorResponse | any) {

    const _self = this;
    let errorMessage: string;
    let body: any;
    if (errorObj instanceof HttpErrorResponse) {
      if (errorObj.status === 401) {

        setTimeout(() => {
        }, 3000)
        this.router.navigateByUrl("");

      } else if (errorObj.status === 403) {

        errorMessage = errorObj.error.message;
        return throwError(errorMessage);
      } else if (errorObj.ok === false && errorObj.status === 0) {
        errorMessage =
          "No internet connection or server might be unreachable. Please try again after sometime.";
        return throwError(errorMessage);
      } else {
        body = errorObj.error || "";
        errorMessage = body.message;
        return throwError(errorMessage);
      }
    }

  }


  downloadExcelData(excelName, url, data) {

    let excel = null;
    if(data.fromDate != null && data.toDate != null){

       excel = excelName+" from "+data.fromDate+" to "+data.toDate+".xlsx";
    }else{
      excel = excelName+".xlsx";
    }

    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }


    var xhr = new XMLHttpRequest();
    xhr.open('POST', environment.backendUrl + url, true);
    xhr.responseType = "arraybuffer";
    $('.loader').fadeIn(); //to show the loader until document loads 
    xhr.onprogress = function updateProgress($evt) {
      if ($evt.lengthComputable) {
        if ($evt.loaded == $evt.total) {
          $('.loader').fadeOut();

        }
      }
    };
    xhr.addEventListener("load", function ($evt) {
      let target: any = $evt.target;

      var data = target.response;

      if (this.status === 200) {

        var downloadDocument = document.createElement('a');
        document.body.appendChild(downloadDocument);
        downloadDocument.setAttribute('style', 'display: none');
        downloadDocument.setAttribute('target', '_blank');
        downloadDocument.href = URL.createObjectURL(new Blob([data],
          {
            type: "application/xlsx"
          }));
        downloadDocument.download = excel;
        downloadDocument.click();
      }
    }, false);
    xhr.setRequestHeader('X-AUTH-TOKEN', localvariable);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(JSON.stringify(data));

    return false;
  }

  uploadAndDownloadExcelData(excelName, url, data) {

    let localvariable = "";
    if (localStorage.getItem("authToken")) {
      localvariable = localStorage.getItem("authToken");
    }


    var xhr = new XMLHttpRequest();
    xhr.open('POST', environment.backendUrl + url, true);
    xhr.responseType = "arraybuffer";
    $('.loader').fadeIn(); //to show the loader until document loads 
    xhr.onprogress = function updateProgress($evt) {
      if ($evt.lengthComputable) {
        if ($evt.loaded == $evt.total) {
          $('.loader').fadeOut();

        }
      }
    };
    xhr.addEventListener("load", function ($evt) {
      let target: any = $evt.target;

      var data = target.response;

      if (this.status === 200) {

        var downloadDocument = document.createElement('a');
        document.body.appendChild(downloadDocument);
        downloadDocument.setAttribute('style', 'display: none');
        downloadDocument.setAttribute('target', '_blank');
        downloadDocument.href = URL.createObjectURL(new Blob([data],
          {
            type: "application/xlsx"
          }));
        downloadDocument.download = excelName;
        downloadDocument.click();
      }
    }, false);
    xhr.setRequestHeader('X-AUTH-TOKEN', localvariable);
    //  xhr.setRequestHeader("Content-type", "application/json"); 
    xhr.send(data);

  }

  addZeroes(num: String) {
    var value: any = Number(num);
    value = value.toFixed(2);
    return value;
  }

  /**
   * 
   * @param data Author Rahul
   * @param fileName 
   */
  downloadExcel(data, dataFilter, fileName, contentType) {
    let excel = null;
    if(dataFilter.fromDate != null && dataFilter.toDate != null){

       excel = fileName+" from "+dataFilter.fromDate+" to "+dataFilter.toDate+".xlsx";
    }else{
      excel = fileName+".xlsx";
    }
    const content = 'data:' + contentType + ';base64,';
    this.srcFile = this.sanitizer.bypassSecurityTrustResourceUrl(
      content + data
    );
    const element = document.createElement('a');
    element.setAttribute('href', content + data);
    element.setAttribute('download', excel);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getEncryptionPasswordService(url) {
    return this.http.get(environment.backendUrl + url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  postEncryptionPasswordService(url, data) {

    let localvariable = '';
    if (localStorage.getItem('random')) {
      localvariable = localStorage.getItem('random');
    }
    const headerJson = {
      'Content-Type': 'application/json',
      'RANDOM-UID': localvariable
    };
    const httpOptions = {
      headers: new HttpHeaders(headerJson)
    };

    return this.http.post(environment.backendUrl + url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }
  // getPublicKey () {

  //   if(this.publicKeyCount <= 3){

  //   this.jsEncrypt = new JsEncryptModule.JSEncrypt();
  //   this.getEncryptionPasswordService(ApiService.apiList.getPublickey)
  //    .subscribe(response => {

  //      if(response.status){
  //       this.publicKey = response.payLoad.publicKey;
  //       this.randomKey = response.payLoad.randomUid;
  //       this.jsEncrypt.setPublicKey(this.publicKey);
  //       localStorage.setItem('random', this.randomKey);
 
  //       // Send AES info data
  //      this.sendAeKey();
  //      }else{
  //       this.publicKeyCount++;
  //       this.getPublicKey ();
  //      }
     
  //    });
  //   }else{
  //   this.toastr.error("Please try again...");
  //   }

  //  }

  // sendAeKey() {

  //   const data = {
  //     'secretKey': this.salt,
  //     'iv': this.iv,
  //     'passPhrase': this.passPhrase,
  //     'deviceType': 'WEB_CRM'
  //   };

  //   const payload = {
  //     'payLoad' : this.jsEncrypt.encrypt(JSON.stringify(data))
  //   };

  //   this.postEncryptionPasswordService(ApiService.apiList.saveAesInfo, payload)
  //     .subscribe(response => {
  //     if (response.status) {
  //       var genKey = this.encryption.generateKey(this.salt, this.passPhrase);
  //       localStorage.setItem('identificationNo', JSON.stringify(genKey));
  //       localStorage.setItem('iv', this.iv);
  //        this.router.navigateByUrl('msig/dashboard');
  //     }
  //     });
  // }

}
