import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { ApiService } from '../../services/api.service';
import { EncryptionService } from 'src/app/services/encryption.service';

declare var $: any;

@Component({
  selector: 'app-cancer-care-complete-payment',
  templateUrl: './cancer-care-complete-payment.component.html',
  styleUrls: ['./cancer-care-complete-payment.component.scss']
})
export class CancerCareCompletePaymentComponent implements OnInit {



  sessionId: string=null;
  merchantId: string=null;
  redirectHtml: string;
  isLoading: boolean = false;
  
    constructor(
      private route: ActivatedRoute,
      private http: HttpClient,
      public toastr: ToastrService,
      private router: Router,
      private apiService: ApiService,
      private encryption: EncryptionService
  
    ) { 
      this.route.queryParams.subscribe(params => {
  
          // this.sessionId = params['sessionId'];
          // console.log("this.sessionId: ", window.atob(params['sessionId']));
          this.updateMpgsPayment(window.atob(params['sessionId']));
      });
     }
  
      ngOnInit() {
       
      }
    
      updateMpgsPayment(sessionId:string){
        this.isLoading = true;
        // console.log("updateMpgsPayment api called sessionId: ", sessionId);
        this.get_service_by_id(ApiService.apiList.updateCancerCarePayment, sessionId).subscribe(response=>{
        
      
          this.isLoading = false;
          // console.log(response);
      
          // console.log("txnId: "+response.txnId);
          if(response.status && response.payLoad.status==='ISSUED'){
            this.router.navigateByUrl("payment-success?txnId="+response.payLoad.txnId);
          }else{
            this.toastr.error(response.message); 
            this.router.navigateByUrl("payment-failure?txnId="+response.payLoad.txnId);
          }
        },
        error => {
          this.isLoading = false;
          // console.log("updateMpgsPayment error : ", error);
          this.toastr.error(error);
          this.router.navigateByUrl("payment-failure");
        }
        );
    
      }
      
      get_service_by_id(url, id) {
        let random = localStorage.getItem('paymentRandom');
      
        var genKey = JSON.parse(localStorage.getItem('paymentIdentificationNo'));
        var iv = (localStorage.getItem('paymentIv'));
      
        var ciphertext = this.encryption.encrypt(genKey, iv, JSON.stringify(id));
      
        let headerJson = {
          "Content-Type": "application/json",
          'RANDOM-UID': random,
          'ID': ciphertext
        };
        const httpOptions = {
          headers: new HttpHeaders(headerJson)
        };
        
        return this.http.get(environment.backendUrl + url, httpOptions).pipe(
          map((response: any) => {
            if (response.status) {
              var responseString = this.encryption.decrypt(genKey,iv, response.payLoad);
            return JSON.parse(responseString);
            }
          }),
          catchError(error => (error))
        );
      }
    
  }
